import React from 'react'
import Link from 'next/link'
import { Tooltip, Row } from 'antd'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import useT from '@/hooks/useT'
type Props = {
  searchLink: string
}

const SearchButton = ({ searchLink }: Props): JSX.Element => {
  const { t } = useT()
  return (
    <Link href={searchLink} style={{ height: '100%' }}>
      <Tooltip title={t('Search')} placement='bottom'>
        <Row style={{ height: '100%' }} align='middle'>
          <SearchOutlined
            style={{
              fontSize: '20px'
            }}
          />
        </Row>
      </Tooltip>
    </Link>
  )
}
export default SearchButton
