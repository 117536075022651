import React from 'react'
import Link from 'next/link'
import { Dropdown } from 'antd'
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined'

type Props = {
  t: any
}
const AddDropdown = ({ t }: Props): JSX.Element => {
  return (
    <Dropdown
      menu={{
        items: [
          { key: 'map', label: <a href='/map/new'>{t('New Map')}</a> },
          {
            key: 'layer',
            label: <Link href='/create/layer'>{t('New Layer')}</Link>
          },
          {
            key: 'group',
            label: <Link href='/create/group'>{t('New Group')}</Link>
          },
          {
            key: 'import',
            label: <Link href='/layer/import'>{t('Import MapHubs File')}</Link>
          }
        ]
      }}
      trigger={['hover']}
    >
      <PlusCircleOutlined
        style={{
          fontSize: '20px'
        }}
      />
    </Dropdown>
  )
}
export default AddDropdown
