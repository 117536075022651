import React from 'react'
import useT from '@/hooks/useT'
import { localeUtil } from '@maphubs/locales'
import { Dropdown } from 'antd'

// import icons individually for better dev build performance
import DownOutlined from '@ant-design/icons/DownOutlined'

const supportedLangs = localeUtil.getSupported()
let languagesFromConfig
const langs = []

if (process.env.NEXT_PUBLIC_LANGUAGES) {
  languagesFromConfig = process.env.NEXT_PUBLIC_LANGUAGES.split(',')
  languagesFromConfig = languagesFromConfig.map((lang) => lang.trim())
  supportedLangs.map((lang) => {
    if (languagesFromConfig.includes(lang.value)) {
      langs.push(lang)
    }
  })
}

const LocaleChooser = (): JSX.Element => {
  const { t, locale, setLocale } = useT()

  const label = localeUtil.getConfig(locale).label

  const menu = {
    items: langs.map((l) => {
      return {
        key: `locale-${l.value}`,
        label: (
          <a
            href='#!'
            id={l.value}
            onClick={() => {
              setLocale(l.value)
            }}
          >{`${l.name} (${l.label})`}</a>
        )
      }
    })
  }

  return (
    <Dropdown menu={menu} trigger={['click']}>
      <a className='nav-link-item' href='#'>
        {label} <DownOutlined />
      </a>
    </Dropdown>
  )
}
export default LocaleChooser
