import React from 'react'
import Link from 'next/link'
import { Dropdown } from 'antd'

// import icons individually for better dev build performance
import DownOutlined from '@ant-design/icons/DownOutlined'

const ExploreDropdown = ({ t }: { t: (v: string) => string }): JSX.Element => {
  return (
    <Dropdown
      menu={{
        items: [
          { key: 'maps', label: <Link href='/maps'>{t('Maps')}</Link> },
          { key: 'layers', label: <Link href='/layers'>{t('Layers')}</Link> },
          { key: 'groups', label: <Link href='/groups'>{t('Groups')}</Link> }
        ]
      }}
      trigger={['click']}
    >
      <a href='#'>
        {t('Explore')} <DownOutlined />
      </a>
    </Dropdown>
  )
}
export default ExploreDropdown
