import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useT from '@/hooks/useT'
import { Dropdown } from 'antd'
import UserOutlined from '@ant-design/icons/UserOutlined'
import urlencode from 'urlencode'
import { signOut, useSession } from '@maphubs/auth'

const UserMenu = ({ sidenav }: { sidenav?: boolean }): JSX.Element => {
  const { t } = useT()
  const router = useRouter()
  const { data: session } = useSession()

  let userMenu

  if (session) {
    const { role } = session

    userMenu = !sidenav ? (
      <Dropdown
        menu={{
          items: [
            {
              key: 'my-maps',
              label: <Link href={`/user/maps`}>{t('My Maps')}</Link>
            },
            {
              key: 'my-groups',
              label: <Link href={`/user/groups`}>{t('My Groups')}</Link>
            },
            {
              key: 'settings',
              label: <Link href='/user/profile'>{t('Settings')}</Link>
            },
            { type: 'divider' },
            {
              key: 'manage',
              label:
                role === 'admin' ? (
                  <Link href='/admin/manage'>{t('Manage Users')}</Link>
                ) : (
                  <></>
                )
            },
            {
              key: 'logout',
              label: (
                <a
                  onClick={(e) => {
                    e.preventDefault()
                    signOut()
                  }}
                >
                  {t('Logout')}
                </a>
              )
            }
          ]
        }}
        trigger={['hover']}
      >
        <UserOutlined
          style={{
            fontSize: `20px`
          }}
        />
      </Dropdown>
    ) : (
      // sidenav
      <>
        <Link href={`/user/maps`}>{t('My Maps')}</Link>
        <Link href={`/user/groups`}>{t('My Groups')}</Link>
        <Link href='/user/profile'>{t('Settings')}</Link>
        {role === 'admin' && (
          <Link href='/admin/manage'>{t('Manage Users')}</Link>
        )}
      </>
    )
  } else {
    userMenu = (
      <a
        style={{
          float: !sidenav ? 'left' : 'inherit'
        }}
        href='#'
        onClick={() => {
          router.push('/login?returnTo=' + urlencode(window.location.href))
        }}
      >
        {t('Login')}
      </a>
    )
  }

  return <>{userMenu}</>
}
export default UserMenu
