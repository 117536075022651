import React, { useState } from 'react'
import Link from 'next/link'
import { Layout, Menu, Drawer, Row } from 'antd'
import useT from '@/hooks/useT'
import ExploreDropdown from './Header/ExploreDropdown'
import AddDropdown from './Header/AddDropdown'
import HelpDropdown from './Header/HelpDropdown'
import SearchButton from './Header/SearchButton'
import LocaleChooser from './LocaleChooser'
import UserMenu from './Header/UserMenu'
import { LocalizedString } from '@maphubs/mhtypes'
import { useSession } from '@maphubs/auth'

// import icons individually for better dev build performance
import MenuOutlined from '@ant-design/icons/MenuOutlined'

const { Header } = Layout
type Link = {
  href: string
  label: LocalizedString
}

export type HeaderConfig = {
  logoLinkUrl?: string
  showSearch?: boolean
  showHelp?: boolean
  customSearchLink?: string
  customHelpLink?: string
  showMakeAMap?: boolean
  showExplore?: boolean
  showAdd?: boolean
  customLinks?: Array<Link>
  theme?: {
    backgroundColor?: string
    fontColor?: string
  }
}
type Props = {
  activePage?: string
} & HeaderConfig

const MapHubsHeader = ({
  customHelpLink,
  showHelp,
  activePage,
  customLinks,
  showMakeAMap,
  showSearch,
  customSearchLink,
  showExplore,
  logoLinkUrl,
  theme,
  showAdd
}: Props): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const { data: session } = useSession()

  const { t } = useT()

  const renderMenu = (className: string, mode: any): JSX.Element => {
    const menuItems = []

    if (showMakeAMap) {
      menuItems.push({
        key: 'makeamap',
        label: <a href='/map/new'>{t('Make a Map')}</a>
      })
    }
    if (showExplore) {
      menuItems.push({
        key: 'explore',
        label: <ExploreDropdown t={t} />
      })
    }
    if (customLinks) {
      // eslint-disable-next-line unicorn/no-array-for-each
      customLinks.forEach((link, i) => {
        menuItems.push({
          key: `nav-custom-link-${i}`,
          label: <a href={link.href}>{t(link.label)}</a>
        })
      })
    }
    menuItems.push({
      key: 'locale',
      label: <LocaleChooser />
    })
    if (showAdd) {
      if (mode !== 'vertical') {
        menuItems.push({
          key: 'add',
          label: (
            <Row align='middle' style={{ height: '100%' }}>
              <AddDropdown t={t} />
            </Row>
          )
        })
      } else {
        menuItems.push(
          { type: 'divider' },
          { key: 'map', label: <a href='/map/new'>{t('New Map')}</a> },
          {
            key: 'layer',
            label: <Link href='/create/layer'>{t('New Layer')}</Link>
          },
          {
            key: 'group',
            label: <Link href='/create/group'>{t('New Group')}</Link>
          },
          {
            key: 'import',
            label: <Link href='/layer/import'>{t('Import MapHubs File')}</Link>
          },
          { type: 'divider' }
        )
      }
    }
    if (showSearch) {
      menuItems.push({
        key: 'search',
        label: (
          <>
            {mode === 'vertical' && (
              <a href={customSearchLink || '/search'}>{t('Search')}</a>
            )}
            {mode !== 'vertical' && (
              <Row align='middle' style={{ height: '100%' }}>
                <SearchButton searchLink={customSearchLink || '/search'} />
              </Row>
            )}
          </>
        )
      })
    }

    if (showHelp) {
      if (mode !== 'vertical') {
        menuItems.push({
          key: 'help',
          label: (
            <Row align='middle' style={{ height: '100%' }}>
              <HelpDropdown customHelpLink={customHelpLink} />
            </Row>
          )
        })
      } else {
        menuItems.push({
          key: 'help',
          label: (
            <a href={customHelpLink || '/help'}>{t('Help Documentation')}</a>
          )
        })
      }
    }

    if (mode !== 'vertical') {
      menuItems.push({
        key: 'user',
        label: (
          <Row align='middle' style={{ height: '100%' }}>
            <UserMenu sidenav={mode === 'vertical'} />
          </Row>
        )
      })
    } else {
      //sidenav
      menuItems.push(
        {
          type: 'divider'
        },
        {
          key: 'my-maps',
          label: <Link href={`/user/maps`}>{t('My Maps')}</Link>
        },
        {
          key: 'my-groups',
          label: <Link href={`/user/groups`}>{t('My Groups')}</Link>
        },
        {
          key: 'settings',
          label: <Link href='/user/profile'>{t('Settings')}</Link>
        }
      )
      if (session?.role === 'admin') {
        menuItems.push({
          key: 'manage-users',
          label: <Link href='/admin/manage'>{t('Manage Users')}</Link>
        })
      }
    }

    return (
      <Menu
        mode={mode}
        className={`${className} nav-menu`}
        defaultSelectedKeys={[activePage]}
        style={{
          height: '50px',
          lineHeight: '50px',
          textAlign: mode === 'vertical' ? 'left' : 'right',
          justifyContent: 'flex-end'
        }}
        items={menuItems}
      />
    )
  }

  const { fontColor } = theme || {}
  const NavMenu = renderMenu('desktop-menu', 'horizontal')
  const MobileMenu = renderMenu('mobile-menu', 'vertical')
  return (
    <Header
      style={{
        padding: 0,
        height: '50px',
        borderBottom: '1px solid rgba(5,5,5,0.06)'
      }}
    >
      <div
        className='logo'
        style={{
          float: 'left'
        }}
      >
        <Link className='valign-wrapper' href={logoLinkUrl}>
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img
            className='valign'
            width={process.env.NEXT_PUBLIC_LOGO_WIDTH}
            height={process.env.NEXT_PUBLIC_LOGO_HEIGHT}
            style={{
              margin: '5px'
            }}
            src={process.env.NEXT_PUBLIC_LOGO}
            alt={process.env.NEXT_PUBLIC_PRODUCT_NAME + ' ' + t('Logo')}
          />
        </Link>
      </div>
      <style jsx global>
        {`
          .ant-menu-horizontal > .ant-menu-item {
            vertical-align: top;
          }

          @media (max-width: 1000px) {
            .hamburger-menu {
              display: block !important;
            }
            .desktop-menu {
              display: none;
            }
          }
        `}
      </style>
      {NavMenu}
      <MenuOutlined
        className='hamburger-menu'
        style={{
          fontSize: '24px',
          color: fontColor || 'inherit',
          position: 'absolute',
          top: '10px',
          right: '10px',
          display: 'none'
        }}
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        bodyStyle={{
          padding: 0,
          height: '100%'
        }}
        placement='right'
        closable={false}
        onClose={() => {
          setVisible(false)
        }}
        open={visible}
      >
        <div>{MobileMenu}</div>
      </Drawer>
    </Header>
  )
}
MapHubsHeader.defaultProps = {
  logoLinkUrl: '/',
  showSearch: true,
  showHelp: true,
  showMakeAMap: true,
  showExplore: true,
  showAdd: true,
  customLinks: []
}
export default MapHubsHeader
