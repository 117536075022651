import React from 'react'
import { useSession } from '@maphubs/auth'
import { Dropdown, message } from 'antd'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import useT from '@/hooks/useT'
type Props = {
  customHelpLink?: string
}
const HelpDropdown = ({ customHelpLink }: Props): JSX.Element => {
  const { t } = useT()
  const { data: session } = useSession()

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'help-docs',
            label: (
              <a href={customHelpLink || 'https://help.maphubs.com'}>
                {t('Help Documentation')}
              </a>
            )
          },
          {
            key: 'feedback',
            label: (
              <a
                href='#'
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  const UserbackInstance = Userback

                  if (UserbackInstance) {
                    if (session?.user?.email)
                      UserbackInstance.email = session.user.email

                    UserbackInstance.open()
                  } else {
                    message.info(
                      'Feedback tool not enabled, please contact support@maphubs.com'
                    )
                  }
                }}
              >
                {t('Questions/Feedback')}
              </a>
            )
          }
        ]
      }}
      trigger={['hover']}
    >
      <QuestionCircleOutlined
        style={{
          fontSize: '20px'
        }}
      />
    </Dropdown>
  )
}
export default HelpDropdown
